import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { CustomSnackbarComponent } from '../../shared/components/custom-snackbar/custom-snackbar.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-create-presentation-modal',
  templateUrl: './create-presentation-modal.component.html',
  styleUrls: ['./create-presentation-modal.component.scss']
})
export class CreatePresentationModalComponent implements OnInit, OnDestroy {

  tapSelected = 'styles';

  showTextAutosaved = false;
  showDownloadPptButton = false;
  isExecuted = false;
  loading = false;

  selectedInfluencersResponse = [];
  influencersCheckboxSelectedForRequest = [];
  configurationStylesForRequest = {};

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _campaignService: CampaignsService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,

  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onInfluencerIdsSelected(influencersIds: string[]) {
    this.selectedInfluencersResponse = influencersIds;
    this.showDownloadPptButton = this.selectedInfluencersResponse.length > 0;
    
    // Verifica si ya fue ejecutada
    if (!this.isExecuted) {
      this.influencersCheckboxSelectedForRequest = this.selectedInfluencersResponse;
      this.isExecuted = true; // Cambia la bandera para que no se ejecute de nuevo
    }


  }

  showAutosaved() {
    this.showTextAutosaved = true;
    setTimeout( () => { this.showTextAutosaved = false; }, 4000 );
  }

  downloadPpt() {
    this.loading = true;
    // Hacemos una copia profunda de this.data.campaign
    let campaignCopy = JSON.parse(JSON.stringify(this.data.campaign));
    
    // Crear el array temporal con los influencers seleccionados
    let temporalArrayInfluencers = [];
    campaignCopy.influencers.forEach((influencer, index ) => {
      if (this.influencersCheckboxSelectedForRequest[index] === influencer.influencer_id) {
        temporalArrayInfluencers.push(influencer);
      }
    });
    
    // Creación del objeto requestForPresentation
    let requestForPresentation = {
      campaign: campaignCopy
    }
    
    // Reemplazamos los influencers por los seleccionados en la copia del objeto
    requestForPresentation.campaign.influencers = temporalArrayInfluencers;
    
    this.subscriptions.push(
      this._campaignService.exportPpt(this.data.campaign_id, requestForPresentation, this.configurationStylesForRequest).subscribe( response => {
        this.descargarArchivo(response.body.download_url);
        setTimeout( () => { 
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: response.body.message, type: 'success' },
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
          this.loading = false;
        }, 1000 );
      }, () => {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
        this.loading = false;
      })
    );
  }

  descargarArchivo(url: string) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'miarchivo.pptx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  canActivateDownloadPpt(event) {
    this.showDownloadPptButton = event.length > 0;
  }

  updateValueCheckboxSelected(event) {
    this.influencersCheckboxSelectedForRequest = event;
  }


  updateConfigurationStyles(event) {
    this.configurationStylesForRequest = event;
  }

}
