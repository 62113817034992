import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {MatDialog} from '@angular/material';
import {ModalConfirmUpgradePlanComponent} from '../../features/modal-plans/components/modal-plans/modal-plans-card/modal-confirm-upgrade-plan/modal-confirm-upgrade-plan.component';
import {ModalPlansComponent} from '../../features/modal-plans/components/modal-plans/modal-plans.component';
import {Observable, of} from 'rxjs';
import {AppThemeService} from './app.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as actions from '../app/app.actions';
import {ModalPreviousPlansComponent} from '../../features/modal-plans/components/modal-previous-plans/modal-previous-plans.component';
import {ModalPlanLimitsExceededComponent} from '../../features/modal-plans/components/modal-plan-limits-exceeded/modal-plan-limits-exceeded.component';

@Injectable()
export class AppEffects {
    constructor(
        private actions$: Actions,
        private dialog: MatDialog,
        private themeService: AppThemeService
    ) {}

    getUserGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getUserGroup),
            switchMap(action => {
                return this.themeService.getUserGroup().pipe(
                    map(userGroup => {
                        this.themeService.processTheme(userGroup['theme_style']);
                        return actions.getUserGroupSuccess({ userGroup });
                    }),
                    catchError(error => of(actions.getUserGroupFail({error})))
                );
            })
        )
    );

    getPublicUserGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getPublicUserGroup),
            switchMap(action => {
                return this.themeService.getPublicUserGroup(action.typeUserGroup, action.queryValue).pipe(
                    map(userGroup => {
                        this.themeService.processTheme(userGroup['theme_style']);
                        return actions.getPublicUserGroupSuccess( { userGroup });
                    }),
                    catchError(error => of(actions.getPublicUserGroupFail({ error })))
                );
            })
        )
    );

    openConfirmUpgradePlanDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.openConfirmUpgradePlanDialog),
            switchMap(action => this.openConfirmUpgradePlanDialog(action)),
            map(response => actions.closeConfirmUpgradePlanDialog())
        )
    );

  openPreviousPlansDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.openPreviousPlansDialog),
      switchMap(action =>
        this.openPreviousPlansDialog()
      ),
      map(response => actions.closePreviousPlansDialog())
    )
  );

    openPlansDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.openPlansDialog),
            switchMap(action =>
                this.openPlansDialog()
            ),
            map(response => actions.closePlansDialog())
        )
    );


    openLimitsPlansExceedDialog$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.openLimitsPlansExceedDialog),
            switchMap(action =>
                this.openLimitslansExceedDialog(action.planName, action.errorMessage)
            ),
            map(response => actions.closeLimitsPlansExceedDialog())
        )
    );

    private openConfirmUpgradePlanDialog(action): Observable<any> {
        return this.dialog
            .open(ModalConfirmUpgradePlanComponent, {
                width: 'auto',
                height: 'auto',
                panelClass: 'container-modal-plans',
                autoFocus: false,
                data: {
                    productId: action.productId,
                    planName: action.plan.planName,
                    plan: action.plan
                }
            })
            .afterClosed();
    }

    private openPlansDialog(): Observable<any> {
        return this.dialog
            .open(ModalPlansComponent, {
                width: '1400px',
                // height: '1000px',
                height: '100%',
                panelClass: 'container-modal-plans',
                autoFocus: false,
                data: {
                }
            })
            .afterClosed();
    }

  private openPreviousPlansDialog(): Observable<any> {
    return this.dialog
      .open(ModalPreviousPlansComponent, {
        width: '75%',
        height: 'auto',
        panelClass: 'container-modal-plans',
        autoFocus: false,
      })
      .afterClosed();
  }


    private openLimitslansExceedDialog(planName, errorMessage): Observable<any> {
        return this.dialog
            .open(ModalPlanLimitsExceededComponent, {
                width: 'auto',
                height: 'auto',
                panelClass: 'container-modal-plans',
                autoFocus: false,
                data: {
                    planName: planName,
                    errorMessage: errorMessage,
                }
            })
            .afterClosed();
    }

}
