import { Component, OnInit } from '@angular/core';
import { ModalPausePlanComponent } from '../modal-pause-plan/modal-pause-plan.component';
import { MatDialog } from '@angular/material';
import { ModalCancelPlanComponent } from '../modal-cancel-plan/modal-cancel-plan.component';

@Component({
  selector: 'app-modal-select-cancel-pause-plan',
  templateUrl: './modal-select-cancel-pause-plan.component.html',
  styleUrls: ['./modal-select-cancel-pause-plan.component.scss']
})
export class ModalSelectCancelPausePlanComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }


  openPauseSubscriptionModal() {
    this.dialog
    .open(ModalPausePlanComponent, {
      width: '415px',
      height: '474px',
      autoFocus: false,
      data: {
      }
    })
    .afterClosed();
  }


  openCancelSubscriptionModal() {
    this.dialog
    .open(ModalCancelPlanComponent, {
      width: '397px',
      height: 'auto',
      // height: '395px',
      autoFocus: false,
      panelClass: 'remove-padding',
      data: {
      }
    })
    .afterClosed();
  }

}
