import {Component, Input, OnInit} from '@angular/core';
import {ViewMoreModalComponent} from '../../modals/view-more-modal/view-more-modal.component';
import {MatDialog} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-interests-affinity',
  templateUrl: './interests-affinity.component.html',
  styleUrls: ['./interests-affinity.component.scss']
})
export class InterestsAffinityComponent implements OnInit {

  @Input() data;
  @Input() disableEvents;
  @Input() tittleModal;
  
  
  col1: string;

  constructor(
      private dialog: MatDialog,
      private translate: TranslateService
  ) { }

  ngOnInit() {
    this.data.forEach(data => {
      if (Object.keys(data)[1] === 'icon') {
        this.col1 = 'icon';
      } else {
        this.col1 = 'thumbnail';
      }
    });
  }

  errorHandler(event) {
    event.target.src = '/assets/img/png/user_not_exists.png';
  }

  openViewMoreModal(data) {
    console.log('que nos viene aqui', data);
    console.log(this.tittleModal);

    if (this.tittleModal === 'affinity') {
      if (this.disableEvents) {
        event.preventDefault();
        event.stopPropagation();
        return;
      } else {
      this.dialog.open(ViewMoreModalComponent, {
        width: 'auto',
        autoFocus: false,
        maxHeight: '90vh',
        data: {
          // data: data,
          // col1: this.col1,
          // col2: 'names',
          // col3: 'weight',
          // col4: 'affinity',
          // estas lineas son las nuevas
          data: data,
          col1: this.col1,
          col2: 'names',
          //hasta aqui, deberia de eliminar las 3 de arriba y sustituir por las comentadas
          titleModal:  this.translate.instant('influencerProfile.viewMoreModal.affinity')
        },
      });
    }
    } else {
      this.dialog.open(ViewMoreModalComponent, {
        width: 'auto',
        autoFocus: false,
        maxHeight: '90vh',
        data: {
          data: data,
          col1: this.col1,
          col2: 'names',
          titleModal:  this.translate.instant('influencerProfile.viewMoreModal.interests')
        },
      });
    }
  }
}
