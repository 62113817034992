import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalConfirmedPauseCancelPlanComponent } from '../modal-confirmed-pause-cancel-plan/modal-confirmed-pause-cancel-plan.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-modal-pause-plan',
  templateUrl: './modal-pause-plan.component.html',
  styleUrls: ['./modal-pause-plan.component.scss']
})
export class ModalPausePlanComponent implements OnInit {


  monthSelected = 1;

  actualDate = '';

  reasonPauseSubscriptionForm: FormGroup;

  constructor(
    private dialog: MatDialog
  ) {
    this.reasonPauseSubscriptionForm = new FormGroup({
      'reason': new FormControl('')
    });
   }

  ngOnInit() {
    this.getFormattedDate(1);
  }


  getFormattedDate(monthsToAdd: number): void {
    const today = new Date();
  
    // Sumar los meses
    today.setMonth(today.getMonth() + monthsToAdd);
  
    const day = today.getDate();
    const months = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    const month = months[today.getMonth()];
    const year = today.getFullYear();
  
    this.actualDate =  `${day} ${month} ${year}`;
  }



  pauseSubscription() {
    this.dialog
    .open(ModalConfirmedPauseCancelPlanComponent, {
      width: '397px',
      height: '352px',
      autoFocus: false,
      data: {
        componentCalled: 'paused',
        datePaused: this.actualDate
      }
    })
    .afterClosed();
  }
  

}
