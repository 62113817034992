import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalPausePlanComponent } from '../modal-pause-plan/modal-pause-plan.component';
import { MatDialog } from '@angular/material';
import { ModalConfirmedPauseCancelPlanComponent } from '../modal-confirmed-pause-cancel-plan/modal-confirmed-pause-cancel-plan.component';

@Component({
  selector: 'app-modal-cancel-plan-form',
  templateUrl: './modal-cancel-plan-form.component.html',
  styleUrls: ['./modal-cancel-plan-form.component.scss']
})
export class ModalCancelPlanFormComponent implements OnInit {


  cancelSubscriptionForm: FormGroup;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }


  cancelSubscription() {
    this.dialog
    .open(ModalConfirmedPauseCancelPlanComponent, {
      width: '397px',
      height: 'auto',
      autoFocus: false,
      data: {
       componentCalled: 'cancelled'
      }
    })
    .afterClosed();
  }


  pauseSubscriptionPlan() {
    this.dialog
    .open(ModalPausePlanComponent, {
      width: '415px',
      height: 'auto',
      autoFocus: false,
      data: {
      }
    })
    .afterClosed();
  }

}
