import { Component, OnInit } from '@angular/core';
import { ModalConfirmedPauseCancelPlanComponent } from '../modal-confirmed-pause-cancel-plan/modal-confirmed-pause-cancel-plan.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-modal-reactivate-plan',
  templateUrl: './modal-reactivate-plan.component.html',
  styleUrls: ['./modal-reactivate-plan.component.scss']
})
export class ModalReactivatePlanComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }


  reactivatePlan() {
    this.dialog
    .open(ModalConfirmedPauseCancelPlanComponent, {
      width: '397px',
      height: '352px',
      autoFocus: false,
      data: {
        componentCalled: 'reactivated'
      }
    })
    .afterClosed();
  }


}
